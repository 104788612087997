div.creator.modal-cart {
  display: block;
  top: 75px;
  padding: 0 20px 50px 20px;
  position: absolute;
  left: 0;
  width: 100%;
  background: #ffffff;
  min-height: 40%;
  box-sizing: border-box;
  z-index: 100;
}

div.creator.modal-cart.mobileView {
  min-height: 100%;
  background: rgba(204, 204, 204, 0.3);
  padding: 0;
}

div.creator.modal-cart.mobileView > div.modal-content {
  background-color: #ffffff;
  padding: 0 8px 50px 8px;
}

div.creator.modal-cart > * {
  transform: translateZ(0);
}

div.creator.modal-cart button.close > img {
  width: 24px;
  height: 24px;
}

div.creator.modal-cart button.close {
  margin: 4px 20px 0 0;
  display: block;
  background: none;
  border: 0;
  float: right;
  cursor: pointer;
}

div.creator.modal-cart .menu-title {
  padding: 9px 0;
  text-align: center;
  background-color: #ffffff;
}

div.creator.modal-cart .menu-title > h3 {
  color: #2d5d91;
  font-weight: bold;
  line-height: 32px;
}

div.creator.modal-cart.mobileView {
  font-weight: 300;
}

div.creator.modal-cart.mobileView div.bottom-container {
  margin-top: 25px;
  z-index: 101;
  padding: 12px 8px;
}

div.creator.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  background: rgba(108, 108, 108, 0.4);
  z-index: 1002;
  box-sizing: border-box;
  margin: 0;
  perspective: 100px;
}

div.creator.modal-background.fixed {
  position: fixed;
}

div.creator.modal-background > div {
  box-sizing: border-box;
  margin: 80px auto 0 auto;
  text-align: center;
  background: #ffffff;
  padding: 20px;
  position: relative;
  max-width: 800px;
}

div.creator.modal-background.modal-lg > div {
  max-width: 60%;
}

@media (max-width: 600px){
  div.creator.modal-background.modal-lg > div {
    max-width: 90%;
  }
}

div.creator.modal-background.mobileView > div {
  padding: 10px 8px;
}

div.creator.modal-background.browserView > div {
  animation: creator-modal 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.creator.modal-background.mobileView > div > .modal-close {
  right: 6px;
}

.creator.modal-background > div > .modal-close {
  position: absolute;
  right: -16px;
  top: -16px;
  border: 0;
  background: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
}

@keyframes creator-modal {
  0% {
    transform: translateZ(-10px);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateZ(0);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
  }
}
