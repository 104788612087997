.creator .out-of-zone {
  background: #ffa133e3;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 240px;
  border-radius: 5px;
  padding: 5px;
  font-size: 0.75em;
}

@media (max-width: 1025px) {
  .creator .out-of-zone {
    width: 200px
  }
}

.creator.mobileView .out-of-zone {
  top: -19px;
  width: 195px;
}

.creator .out-of-zone svg {
  vertical-align: middle;
}
