.mobileView.creator li.textComponent > div.menu-component {
  padding-left: 5px;
}

.creator .fontForm {
  width: 100%;
  text-align: left;
}

.creator .fontForm > div > label {
  display: inline-block;
  color: #336699;
  font-size: 1em;
}

.creator .fontForm > div {
  vertical-align: top;
  text-align: left;
  padding-bottom: 10px;
}

.creator .fontForm > div select {
  vertical-align: top;
  height: 18px;
  box-sizing: content-box;
  margin-left: 5px;
}

.creator .fontForm > div.selectFont,
.creator .fontForm > div.selectFontSize,
.creator .fontForm > div.selectCurvedText {
  display: inline-block;
  text-align: left;
}

.creator .fontForm > div.selectCurvedText div.fontCurved button {
  background: none;
  border: #939598 1px solid;
  border-radius: 5px;
  padding: 2px 5px;
  margin: 0 5px 0 0;
  cursor: pointer;
  box-shadow: none;
  user-select: none;
}

.creator .fontForm > div.selectCurvedText div.fontCurved button.active,
.creator .fontForm > div.selectCurvedText div.fontCurved button:hover {
  border-color: #336699;
  background-color: rgba(51, 102, 153, 0.1);
}

.creator .fontForm > div.selectCurvedText div.fontCurved button:hover {
  box-shadow: 0 6px 10px rgba(51, 102, 153, 0.1);
}

.creator .fontForm > div.selectFont {
  width: 75%;
  max-width: 320px;
}

.creator .fontForm > div.selectFontSize {
  width: 20%;
  margin-left: 10px;
  max-width: 90px;
}

.creator .fontForm > div.selectColor > div {
  display: inline-block;
  margin-left: 10px;
}

.creator .fontForm > div.selectColor button.color-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid rgba(204, 204, 204, 0.5);
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-shadow: none;
  user-select: none;
}

@media (max-width: 1213px) {
  .creator .fontForm > div.selectFont,
  .creator .fontForm > div.selectFontSize,
  .creator .fontForm > div.selectColor,
  .creator .fontForm > div.selectCurvedText {
    display: block;
  }

  .creator .fontForm > div.selectFont {
    width: 100%;
  }

  .creator .fontForm > div.selectFontSize {
    width: 100%;
    margin-left: 0;
  }
}

.creator .fontForm > div > textarea {
  width: 95%;
  padding: 4px 8px;
  font-size: 1em;
  box-sizing: border-box;
  background-color: hsl(0, 0%, 98%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  max-width: 426px;
}

.creator .fontForm .selectColor .dot {
  border-radius: 10px;
  content: " ";
  display: inline-block;
  margin-right: 8px;
  height: 18px;
  width: 18px;
}

.creator .fontForm .selectColor > .control > .dot {
  margin-left: 10px;
}

.creator .fontForm .selectColor .selectColor__control {
  padding-left: 8px;
}

.creator .fontForm .selectColor .selectColor__value-container {
  padding: 2px 8px 2px 0 !important;
}
