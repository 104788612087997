.creator .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 20px;
}

.creator .arrow.up {
  transform: rotate(-135deg);
}

.creator .arrow.bottom {
  transform: rotate(45deg);
}

.creator .arrow.left {
  transform: rotate(135deg);
}

.creator .arrow.right {
  transform: rotate(-45deg);
}
