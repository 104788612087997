.creator .productContainer {
  width: 100%;
}

.creator .productContainer .productPreview {
  text-align: left;
  min-height: 108px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  padding: 7px 10px;
  cursor: pointer;
  border: 1px solid #eee;
  user-select: none;
  transition: all 0.25s ease;
  animation: none;
  color: #000000;
}

@keyframes creator-border-color {
  from {
    border-color: #ccc;
  }
  to {
    border-color: #6c6c6c;
  }
}

.creator .productContainer .productPreview > div.productImage {
  flex: 0 0 124px;
  position: relative;
}

.creator .productContainer .productPreview > div.productImage > img {
  max-width: 124px;
}

.creator .productContainer .productPreview > div.productDescription {
  text-align: center;
  flex: 1 0 170px;
}

.creator
  .productContainer
  .productPreview
  > div.productDescription
  > div:nth-child(2) {
  font-weight: 300;
}

.creator
  .productContainer
  .productPreview
  > div.productDescription
  > div:nth-child(3) {
  font-weight: 300;
  margin: 5px 0 0 0;
  color: #2b70c4;
}

.creator .productContainer .productPreview > div.info-icon {
  transition: all 0.25s ease;
  flex: 1;
}

.creator .productContainer .productPreview > div.info-icon > span {
  width: 20px;
  height: 20px;
}

.creator .productContainer .productPreview:hover {
  border: 1px solid #ccc;
  background: #ffffff;
}

.creator .productContainer .productPreview.active {
  border: 1px solid #6c6c6c;
  cursor: default;
}

.creator .productContainer .productPreview > div.info-icon > .icon {
  color: #00a651;
  width: 20px;
  height: 20px;
}

.creator .productComponent > div.navigation-container.itemContainer > div {
  display: inline-block;
}

.creator
  .productComponent
  > div.navigation-container.itemContainer
  > div.categories {
  width: 40%;
  padding: 0 10px 0 0;
  margin: 0;
  box-sizing: border-box;
}

.creator
  .productComponent
  > div.navigation-container.itemContainer
  > div.subcategories {
  width: 60%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.creator
  .productComponent
  > div.navigation-container.itemContainer
  > div.brands {
  width: 100%;
  padding: 0;
  margin: 5px 0 0 0;
  box-sizing: border-box;
}

@media (max-width: 1213px) {
  .creator
    .productComponent
    > div.navigation-container.itemContainer
    > div:first-child {
    width: 100%;
    margin: 0 0 0 0;
  }

  .creator
    .productComponent
    > div.navigation-container.itemContainer
    > div:nth-child(2) {
    width: 100%;
    margin: 10px 0 0 0;
  }

  .creator .productContainer .productPreview > div.productImage {
    flex: 0 0 80px;
    position: relative;
  }

  .creator .productContainer .productPreview > div.productImage > img {
    max-width: 80px;
  }

  .creator .productContainer .productPreview {
    padding: 5px 0;
    margin: 0 0 5px 0;
  }

  .creator .productContainer .productPreview > div.productDescription {
    flex: 1 0 150px;
  }
}

div.creator.mobileView
  .productComponent
  > div.menu-component
  > div:first-child {
  margin-bottom: 10px;
}

.creator.mobileView .navigation .pageLoading {
  min-width: 44px;
  min-height: 44px;
}
