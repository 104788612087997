.creator {
  background: #ffffff;
  color: #000000;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.creator * {
  box-sizing: content-box;
}

.creator p {
  font-size: 0.85em;
  font-weight: 400;
}

.creator ul {
  margin: 0;
}

.creator a:hover {
  text-decoration: none;
}

.creator a {
  text-decoration: none;
  color: #336699;
  transition: all 50ms ease-in-out;
  -webkit-appearance: none;
}

.creator a:hover {
  background-color: #336699;
  color: #ffffff;
}

.creator img {
  vertical-align: initial;
}

.creator .pageLoading {
  animation-duration: 870ms;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f6f6;
  background-image: linear-gradient(
    to right,
    #f6f6f6 8%,
    #f0f0f0 18%,
    #f6f6f6 33%
  );
  background-size: 200% 100%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.creator .pageLoading.green,
.creator .green .pageLoading {
  background-color: rgb(229, 246, 236);
  background-image: linear-gradient(
    to right,
    rgb(229, 246, 236) 8%,
    rgb(223, 240, 230) 18%,
    rgb(229, 246, 236) 33%
  );
}

.creator .pageLoading.yellow,
.creator .yellow .pageLoading {
  background-color: rgb(255, 247, 236);
  background-image: linear-gradient(
    to right,
    rgb(255, 247, 236) 8%,
    rgb(249, 241, 230) 18%,
    rgb(255, 247, 236) 33%
  );
}

.creator .pageLoading.purple,
.creator .purple .pageLoading {
  background-color: rgb(245, 233, 245);
  background-image: linear-gradient(
    to right,
    rgb(245, 233, 245) 8%,
    rgb(239, 227, 239) 18%,
    rgb(245, 233, 245) 33%
  );
}

.creator .pageLoading.red,
.creator .red .pageLoading {
  background-color: rgb(251, 235, 235);
  background-image: linear-gradient(
    to right,
    rgb(251, 235, 235) 8%,
    rgb(245, 229, 229) 18%,
    rgb(251, 235, 235) 33%
  );
}

.creator .pageLoading.blue,
.creator .blue .pageLoading {
  background-color: rgb(51, 102, 153);
  background-image: linear-gradient(
    to right,
    rgb(51, 102, 153) 8%,
    rgb(45, 96, 147) 18%,
    rgb(51, 102, 153) 33%
  );
}
