.filterModal-preview-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.sprite-filter-display{
    padding: 10px;
    border: 1px solid gray;
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.sprite-filter-display img {
    max-width: 120px;
    max-height: 120px;
    margin-bottom: 10px;
    padding: 10px;
}


.sprite-filter-dispaly img.filter-loading{
    -webkit-animation: pulse 1s infinite;
            animation: pulse 1s infinite;
}
@-webkit-keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.8;
    }
  }
@keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.8;
    }
  }
