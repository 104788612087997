.creator .my-motive form.uploadFileForm {
  font-size: 1.25em;
  position: relative;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  outline: 2px dashed #000000;
  outline-offset: -8px;
  cursor: pointer;
  transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  user-select: none;
}

.creator.mobileView .my-motive form.uploadFileForm {
  margin: 0;
  padding: 20px;
  position: relative;
}

.creator .my-motive form.uploadFileForm.is-dragover {
  outline-offset: -10px;
  background-color: rgb(255, 247, 236);
}

.creator .my-motive form.uploadFileForm > input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.creator .my-motive form.uploadFileForm > label > strong {
  cursor: pointer;
}

.creator .my-motive form.uploadFileForm > span.plus {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 50px;
  font-weight: 100;
  border-radius: 50%;
  border: 2px solid black;
  margin: 0 auto;
}

.creator .my-motive > div > div {
  text-align: left;
}

.creator .my-motive div.upload-description {
  box-sizing: border-box;
  padding: 0 10px;
}

.creator .my-motive div.upload-description > div.stars > div > div {
  display: inline-block;
}

.creator .my-motive div.upload-description > div.stars svg {
  margin-top: 6px;
  width: 16px;
  height: 16px;
}

.creator .my-motive div.upload-description > div.title {
  margin-top: 17px;
  margin-bottom: 7px;
}

.creator .my-motive div.upload-description > div.description strong {
  font-weight: 900;
}

.creator .my-motive div.upload-description > div.description span {
  text-decoration: underline;
}
