.creator .bottom-product-component {
  display: flex;
}

.creator.mobileView .bottom-product-component {
  display: block;
}

.creator .bottom-product-component .description {
  display: block;
}

.creator .bottom-product-component .description {
  position: relative;
  display: block;
  overflow: hidden;
}

.creator .bottom-product-component .more-button {
  float: right;
  background: none;
  border: 0;
  padding: 0;
  margin: 8px 0 5px 0;
  width: 25px;
  height: 25px;
}

.creator .bottom-product-component .more-button svg {
  width: 25px;
  height: 25px;
  transition: transform 200ms ease-in-out 50ms;
}

.creator .bottom-product-component .description:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f2f7fa);
}

.creator .bottom-product-component > div {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.creator .bottom-product-component > div.information {
  flex: 50%;
  padding-right: 30px;
  box-sizing: border-box;
}

.creator .bottom-preview-component {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 15px 0 0 0;
  width: 594px;
  will-change: transform;
}

.creator .bottom-preview-component .preview {
  display: inline-block;
  background: none;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.creator .bottom-preview-component .preview img {
  width: auto;
  max-height: 200px;
  vertical-align: top;
}

.creator .bottom-product-component > div.table {
  text-align: left;
  flex: 50%;
}

.creator .bottom-product-component > div.table > table {
  border-collapse: collapse;
}

.creator .bottom-product-component > div.table > div.table-sizes {
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 594px;
  will-change: transform;
}

.creator .bottom-product-component > div.table > div.table-sizes > img {
  max-width: 100%;
}

@media (max-width: 1039px) {
  .creator .bottom-product-component {
    display: block;
  }

  .creator .bottom-product-component > div {
    display: block;
  }

  .creator .bottom-preview-component {
    max-width: 100%;
    width: auto;
  }

  .creator .bottom-product-component > div.table > div.table-sizes {
    max-width: 100%;
  }
}
