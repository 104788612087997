.creator div#similar-product-container > h4.title {
  margin-left: 20px;
}

.creator div#similar-product-container > div {
  position: relative;
  text-align: center;
  user-select: none;
}

.creator
  div#similar-product-container
  > div
  > div.similar-product-container-content {
  overflow-x: hidden;
  white-space: nowrap;
  overflow-y: auto;
  width: 90%;
  margin: 0 10px;
  height: 133px;
  position: relative;
}

.creator.tabletView
  div#similar-product-container
  > div
  > div.similar-product-container-content {
  margin: 0;
}

.creator.tabletView
  div#similar-product-container
  > div
  > div.similar-product-container-content,
.creator.mobileView
  div#similar-product-container
  > div
  > div.similar-product-container-content {
  overflow-x: auto;
  width: 100%;
}

.creator.mobileView
  div#similar-product-container
  div.similar-product-container-content {
  margin: 0 0 10px 0;
}

.creator.mobileView div.similar-product-container-content .pageLoading {
  min-width: 200px;
}

.creator div#similar-product-container > div > div,
.creator div#similar-product-container > div > button {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.creator div#similar-product-container > div > button.arrow-parent {
  width: 12px;
  line-height: 124px;
  height: 124px;
  cursor: pointer;
  background: #ffffff;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.creator div.similar-product-container-content > div {
  height: 124px;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  margin: 0 5px;
  transition: border 0.25s ease;
  vertical-align: top;
}

.creator div.similar-product-container-content > div > div {
  display: inline-block;
  position: relative;
  text-align: left;
  margin: 0 5px 0 5px;
  vertical-align: top;
}

.creator div.similar-product-container-content > div > div > img {
  margin: 0 5px;
}

.creator div.similar-product-container-content > div:hover {
  border-color: #ccc;
}

.creator div.similar-product-container-content > div.active {
  border-color: #6c6c6c;
  cursor: default;
}

.creator div.similar-product-container-content > div.active > div > span {
  font-weight: 400;
}

.creator div.similar-product-container-content > div > div > h4 {
  font-size: 1em;
  white-space: normal;
  max-width: 110px;
  overflow: hidden;
  max-height: 60px;
}

.creator div.similar-product-container-content > div > div > span {
  display: block;
  font-size: 0.8em;
  font-weight: 300;
  color: #000000;
}
