.creator section.mid {
  position: relative;
}

.creator #canvas-container {
  position: relative;
  transition: all 1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  opacity: 0;
  filter: blur(12px);
}

.creator .loading-in-middle {
  position: absolute;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: auto !important;
  min-width: auto !important;
  display: block !important;
  margin: 0 !important;
}

.creator .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.creator .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.creator .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.creator .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.creator .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.creator .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.creator.generatorView #canvas-container {
  filter: none !important;
  transition: none !important;
  opacity: 1 !important;
}

.creator #canvas-container.display {
  opacity: 1;
  filter: blur(0);
}

.creator:not('.mobileView') #canvas-container > canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.creator .sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.creator .sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #f0f0f0;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.creator .sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.creator .sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.creator .sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.creator .sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.creator .sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.creator .sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.creator .sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.creator .sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.creator .sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

.creator.mobileView #canvas-container {
  min-height: 304px;
}

.creator .RemoveBackgroundRequestContainer{
  margin-top: 30px;
  padding: 10px;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
