.creator *:focus {
  outline: none;
}

.creator button.appButton {
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.9em;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  border: 1px solid #336699;
  margin: 0;
  transition: 100ms ease;
  user-select: none;
  display: flex;
  align-items: center;
}

.creator button.appButton > div:first-child {
  flex: 1;
}

.creator button.appButton > div:first-child > span {
  display: block;
  font-size: 0.7em;
}

.creator .buttonBlue {
  background-color: #336699;
  color: #fff;
}

.creator .buttonWhite {
  background-color: #fff;
  color: #336699;
}

.creator .buttonGreen {
  background-color: #00901a;
  color: #ffffff;
  border: 1px solid rgba(0, 166, 81, 0.5) !important;
}

.creator button.appButton[disabled] {
  opacity: 0.4;
  user-select: none;
  cursor: not-allowed;
}

.creator button.appButton > div.icon {
  flex: 0 10px;
  margin-left: 5px;
}

.creator .error {
  font-size: 10px;
  color: #e74c3c;
  padding: 5px;
  background: rgba(231, 76, 60, 0.3);
  border-radius: 4px;
  margin: 3px 0;
  text-align: center;
}

.creator .appButton > .icon svg {
  vertical-align: middle;
  padding: 2px;
}

.creator .appButton > div.rightArrow > span {
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg);
  display: inline-block;
  padding: 4px;
}

.creator .appButton.buttonBlue:enabled:hover {
  background-color: #fff;
  color: #336699;
  box-shadow: 0 6px 10px rgba(51, 102, 153, 0.3);
}

.creator .appButton.buttonBlue:enabled:hover > div.icon > span {
  border-color: #336699;
}

.creator .appButton.buttonWhite:enabled:hover {
  background-color: #336699;
  color: #fff;
  box-shadow: 0 6px 10px rgba(51, 102, 153, 0.3);
}

.creator .appButton.buttonWhite > div.icon > span {
  border-color: #336699;
}

.creator .appButton.buttonGreen:enabled:hover {
  background-color: #fff;
  color: #00a651;
  box-shadow: 0 6px 10px rgba(0, 166, 81, 0.3);
}

.creator .appButton.buttonGreen > div.icon > span {
  border-color: #ffffff;
}

.creator .appButton.buttonGreen:enabled:hover > div.icon > span {
  border-color: #00a651;
}

.creator .appButton.buttonWhite:enabled:hover > div.icon > span {
  border-color: #fff;
}

.creator div.textStyleButtons {
  display: inline-block;
  margin-left: 5px;
}

.creator div.textStyleButtons > a {
  display: inline-block;
  border: #939598 1px solid;
  border-radius: 5px;
  padding: 4px 6px;
  text-align: center;
  margin: 0 0 0 2px;
  cursor: pointer;
  transition: all 100ms;
  line-height: 16px;
}

.creator div.textStyleButtons > a:nth-child(3) {
  margin-left: 12px;
}

.creator div.textStyleButtons > a.active {
  background-color: #336699;
  border-color: #336699;
}

.creator div.textStyleButtons > a.active > svg,
.creator div.textStyleButtons > a:hover > svg {
  color: #ffffff;
}

.creator div.textStyleButtons > a:hover {
  background-color: #336699;
  border-color: #336699;
  box-shadow: 0 6px 10px rgba(51, 102, 153, 0.5);
}
