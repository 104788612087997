.creator div.active-zone {
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
}

.creator div.active-zone > img {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.creator .generatedImage {
  max-width: 124px;
  max-height: 124px;
}

.creator div.active-zone .centeredContent {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
