.creator .product-color-wrapper {
  position: absolute;
  left: 15px;
  bottom: 20px;
  max-width: 58px;
  font-size: 12px;
  text-align: left;
}

.creator .product-color {
  display: block;
  height: 35px;
  width: 35px;
  border: 1px solid #336699;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
}
