.CheckBox_Container{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.CheckBox_Container > input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.CheckBox_Container > .checkMark{
    display: inline-block;
    position: relative;
    margin-right: 10px;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid rgba(33,66,99,0.5);
}

.CheckBox_Container:hover .checkMark{
    background-color: #eee;
}

.CheckBox_Container input:checked ~ .checkMark:after{
    border-width: 0 3px 3px 0;
    width: 7px;
    height: 14px;
}

.CheckBox_Container .checkMark:after {
    content: "";
    position: absolute;
    left: 8px;
    top: 3px; 
    width: 0px;
    height: 0px;
    border: 0px solid #369;
    transform: rotate(45deg);
  }

