.centeredSpinner {
  width: 70px;
  height: 70px;
  margin-top: -70px;
  margin-left: -70px;
  position: fixed;
  top: 50%;
  left: 50%;
}

.k-loading {
  stroke-dasharray: 1441.316162109375;
  stroke-dashoffset: 1441.316162109375;
  stroke: #1c1c24;
  fill: none;
  -webkit-animation: k-loading 1.8s ease-in-out 900ms infinite alternate;
          animation: k-loading 1.8s ease-in-out 900ms infinite alternate;
}

.tshirt-loading {
  stroke-dasharray: 1805.466064453125;
  stroke-dashoffset: 1805.466064453125;
  stroke: #d42c24;
  fill: none;
  -webkit-animation: tshirt-loading 1.8s ease-in-out infinite alternate;
          animation: tshirt-loading 1.8s ease-in-out infinite alternate;
}

@-webkit-keyframes k-loading {
  40% {
    stroke-dashoffset: 0;
    fill: rgba(28, 28, 36, 0);
  }
  80% {
    fill: rgba(28, 28, 36, 1);
  }
  100% {
    stroke-dashoffset: 0;
    fill: rgba(28, 28, 36, 1);
  }
}

@keyframes k-loading {
  40% {
    stroke-dashoffset: 0;
    fill: rgba(28, 28, 36, 0);
  }
  80% {
    fill: rgba(28, 28, 36, 1);
  }
  100% {
    stroke-dashoffset: 0;
    fill: rgba(28, 28, 36, 1);
  }
}

@-webkit-keyframes tshirt-loading {
  40% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 0);
  }
  80% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 1);
  }
  100% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 1);
  }
}

@keyframes tshirt-loading {
  40% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 0);
  }
  80% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 1);
  }
  100% {
    stroke-dashoffset: 0;
    fill: rgba(212, 44, 36, 1);
  }
}

.creator .dot-loading {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: dot-loading 500ms infinite cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: dot-loading 500ms infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@-webkit-keyframes dot-loading {
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
    opacity: 0.5;
  }
}

@keyframes dot-loading {
  0% {
    transform: scale(0.95);
    opacity: 0.8;
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
    opacity: 0.5;
  }
}
