@media (max-width: 1213px) {
  .creator section.right {
    display: flex;
    justify-content: space-around;
  }

  .creator section.right > div:first-child {
    flex: 1 1 250px;
  }

  .creator section.right > div:nth-child(2) {
    flex: 1 1 250px;
    margin-left: 30px;
  }

  .creator section.right > div:first-child > h2,
  .creator section.right > div:nth-child(2) > div:first-child {
    margin-top: 30px;
  }

  .creator
    section.right
    > div:nth-child(2)
    > div.optionComponent.colorComponent {
    max-width: 307px;
  }
}

.creator section.right > div > div.nextButtons {
  margin-top: 30px;
  text-align: left;
}

.creator section.right > div:first-child {
  text-align: left;
}

.creator section.right > div:nth-child(2) {
  margin-top: 30px;
}

.creator div.description {
  text-align: justify;
}

.creator div.optionComponent {
  text-align: left;
  display: block;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: 30px;
}

.creator.mobileView div.optionComponent {
  margin-top: 0;
  padding-bottom: 20px;
}

.creator.mobileView div.optionComponent span.colorComponent {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.creator div.optionComponent > h3 {
  white-space: nowrap;
  width: 100%;
  text-transform: uppercase;
  font-weight: 200;
}

.creator.modal-cart div.optionComponent > h3 {
  font-size: 0.9em;
  font-weight: 500;
  color: #2d5d91;
}

.creator div.optionComponent > h3 > span {
  font-size: 0.7em;
}

.creator div.optionComponent.price-component > h3 {
  display: inline-block;
  width: auto;
}

.creator div.optionComponent.price-component button.price-discounts {
  font-size: 0.9em;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #ffffff;
  color: #00a651;
  text-decoration: underline;
  text-decoration-style: dotted;
  font-weight: bold;
}

.creator div.optionComponent.price-component button.price-discounts:hover {
  color: #ffffff;
  background-color: #00a651;
  text-decoration: none;
}

.creator div.optionComponent > .data-header {
  font-size: 1.2em;
  font-weight: bold;
  color: #2d5d91;
  margin-left: 8px;
  display: inline-block;
}

.creator div.optionComponent > .data-header .dot-loading {
  width: 20px;
  height: 20px;
}
.creator div.optionComponent > .data-header > span {
  font-size: 0.7em;
  display: block;
  color: #000000;
  font-weight: 300;
}

.creator div.optionComponent > div,
.creator div.optionComponent > ul {
  margin-top: 10px;
  display: inline-block;
  text-align: left;
}

.creator div.optionComponent span.colorComponent {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(204, 204, 204, 0.5);
  display: inline-block;
  padding: 0;
  margin: 0 5px 5px 5px;
  cursor: pointer;
  user-select: none;
}

.creator div.optionComponent span.colorComponent:hover {
  box-shadow: 0 4px 10px rgba(51, 102, 153, 0.5);
}

.creator div.optionComponent span.colorComponent:hover,
.creator div.optionComponent span.colorComponent.active {
  border: 1px solid #336699;
}

.creator div.optionComponent span.colorComponent.active {
  border-radius: 0;
}

.creator div.optionComponent span.colorComponent.animation {
  border-top: 1px solid #336699;
  animation: creator-spin 0.5s linear infinite;
}

ul.sizeComponent {
  list-style-type: none;
  padding: 0;
  display: block;
}

ul.sizeComponent > li {
  display: inline-block;
  color: #336699;
  font-size: 0.7em;
  min-width: 25px;
  line-height: 15px;
  min-height: 21px;
  text-align: center;
  border: #336699 solid 2px;
  border-radius: 3px;
  margin: 0 4px 5px 0;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  transition: all 0.4s ease;
}

ul.sizeComponent > li > .SizeComponent_quantityValue{
  color: green;
}

ul.sizeComponent > li > span {
  display: block;
  font-size: 0.9em;
}

.modal-cart ul.sizeComponent > li {
  margin: 0 15px 15px 0;
}

ul.sizeComponent > li.active {
  cursor: default;
}

ul.sizeComponent > li.active,
ul.sizeComponent > li:hover {
  color: #ffffff;
  background-color: #336699;
}
ul.sizeComponent > li:hover:not(.disabled):not(.active) {
  box-shadow: 0 6px 10px rgba(51, 102, 153, 0.5);
}

ul.sizeComponent > li.disabled {
  cursor: default;
  background-color: rgb(235, 235, 228);
  color: rgb(84, 84, 84);
  border-color: rgb(84, 84, 84);
}

ul.sizeComponent > li > span {
  color: #336699;
  font-weight: bold;
}

ul.sizeComponent > li.active > span,
ul.sizeComponent > li:hover > span {
  color: #ffffff;
}

ul.sizeComponent > li.disabled > span {
  color: #fb3f18;
  font-weight: normal;
}
