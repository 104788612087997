.creator .title {
    color: #336699;
    text-align: left;
}

.creator h1, .creator h2 {
    font-size: 1.5em;
}

.creator h1.title {
    text-transform: uppercase;
    margin-bottom: 18px;
}

.creator h2.title {
    margin-bottom: 16px;
}

.creator h4.title {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.3em;
  font-weight: 300;
}

.creator h3 {
    font-size: 1.25em;
    font-weight: normal;
    display: inline-block;
    margin: 0;
    vertical-align: top;
}

.creator h5 {
  color: black;
  margin: 15px 0 2px 0;
}
