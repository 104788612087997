.debug-info {
  position: absolute;
  top: 30px;
  font-size: 0.7em;
  min-width: 250px;
}

.debug-right {
  right: 50px;
}

.debug-left {
  left: 50px;
}

.debug-info .content {
  background: rgba(85, 85, 85, 0.8);
  color: #ffffff;

  padding: 7px;
  border-radius: 5px;
  margin: 0 0 10px 0;
}

.debug-info span {
  display: block;
}

.debug-info h2 {
  margin: 3px 0;
  padding: 0;
  font-size: 14px;
}
