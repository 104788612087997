div.browserView.creator > section {
  vertical-align: top;
}

div.browserView.creator > section {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 0 20px;
  min-height: 630px;
  min-width: 1003px;
}

/* DESKTOP */
@media (min-width: 1214px) {
  .creator section.left {
    flex: 0 0 426px;
    max-width: 426px;
  }

  .creator section.mid {
    flex: 2 0 518px;
  }

  .creator section.right {
    flex: 0 0 250px;
  }
}

@media (max-width: 1213px) {
  div.browserView.creator > section {
    margin: 0 0 0 3px;
  }

  div.browserView.creator > section {
    flex-flow: row wrap;
    min-width: auto;
  }

  .creator section.left {
    flex: 1 0 240px;
    min-width: 240px;
  }

  .creator section.mid {
    flex: 1 0 518px;
    min-height: 518px;
  }
}
