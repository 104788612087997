.tabletView.creator div.previews,
.browserView.creator div.previews {
  margin-top: 100px;
  margin: auto;
}

.creator div.previews {
  width: 370px;
  overflow-x: auto;
  white-space: nowrap;
}

.mobileView.creator div.previews {
  max-width: 100%;
  width: auto;
  margin: 5px 0 50px 0;
  overflow-x: auto;
}


.creator div.previews > .productPreviewSide.printed {
  border-color: rgb(238, 156, 156);
  border-radius: 0;
}

.creator div.previews > .productPreviewSide.active {
  border-color: #336699;
  border-radius: 0;
}


.creator div.previews > button.productPreviewSide {
  display: inline-block;
  cursor: pointer;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  padding: 0;
  margin: 0 3px 0 0;
  position: relative;
  background: #ffffff;
}

.mobileView.creator div.previews > button.productPreviewSide {
  margin: 0 0 0 3px;
}

.creator div.previews > .productPreviewSide > img {
  width: 80px;
  height: 80px;
}

.mobileView.creator div.previews > .productPreviewSide > img {
  width: 40px;
  height: 40px;
}
