.creator ul.navigation {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.creator.mobileView .navigation {
  padding-top: 10px;
  position: relative;
  padding-bottom: 5px;
}

.creator.mobileView .navigation:before {
  position: absolute;
  width: 85%;
  content: '';
  background: rgba(204, 204, 204, 0.8);
  height: 1px;
  left: 50%;
  top: 79px;
  transform: translateX(-50%);
}

.creator.mobileView .navigation > li.active > div {
  max-height: none;
  display: block;
}

.creator.mobileView .navigation > li > div {
  position: absolute;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;
  box-sizing: border-box;
  transition: none;
  top: 80px;
  max-height: none;
  display: none;
}

.creator.mobileView .navigation > li.hiddenItem {
  margin: 0;
  padding: 0;
}

.creator.mobileView .navigation > li {
  display: inline-block;
  margin: 0 5px;
}

.creator .navigation > li > a {
  text-align: left;
  text-decoration: none;
  height: 40px;
  color: #231f20;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
}

.creator.mobileView .navigation > li > a {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 0;
}

.creator.mobileView .navigation > li > a:hover {
  background-color: transparent;
}

.creator .navigation > li > a > .text {
  font-size: 1.25em;
  line-height: 44px;
  margin-left: 20px;
}

.creator .navigation > li > a > div {
  float: right;
  line-height: 40px;
  margin: 0;
  padding: 0;
}

.creator.mobileView .navigation > li > a > img {
  display: block;
  margin: 0 auto;
  width: 45px;
  height: 45px;
  padding: 0;
}

.creator.mobileView .navigation > li.active > div.menu-component {
  min-height: 100%;
  padding-bottom: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.creator .navigation > li > a > img {
  border: 1px solid;
  width: 28px;
  height: 28px;
  padding: 5px;
  float: left;
}

.creator.mobileView .navigation > li > a > img {
  float: none;
}

.creator.mobileView .navigation > li > a > span {
  margin-top: 5px;
  display: block;
}

.creator .navigation > li > div {
  max-height: 0;
  transition: all 380ms;
  overflow-y: hidden;
}

.creator .navigation > li.active > div {
  max-height: 500px;
  margin-bottom: 15px;
}

/** Insert Text Component - Bug Fix https://github.com/JedWatson/react-select/issues/810 **/
.creator .navigation > li.active > div.itemContainer-enter-done {
  overflow-y: visible;
}

.creator .navigation > li.my-motive > div {
  overflow-y: auto !important;
}

.creator .navigation div.spriteContainer,
.creator .navigation div.productContainer {
  overflow-y: scroll;
  max-height: 454px;
  margin: 10px 0;
  text-align: center;

  /**
    FIX: repaints on scroll
    https://stackoverflow.com/questions/24741502/can-i-do-anything-about-repaints-on-scroll-warning-in-chrome-for-overflowscr
   */
  will-change: transform;
}

.creator .navigation > li > div > div.navigation-container {
  text-align: left;
  width: 100%;
}

.creator .navigation > li > div > div.navigation-container {
  text-align: left;
}

.creator .navigation .menu-title > button.close > img {
  width: 32px;
  height: 32px;
}

.creator.mobileView .navigation .menu-title > button.close > img {
  width: 29px;
  height: 29px;
}

.creator.mobileView .navigation .menu-title > button.close {
  margin: 4px 20px 0 0;
}

.creator .navigation .menu-title {
  margin: 9px 0;
}

.creator .navigation .menu-title.left {
  text-align: left;
}

.creator .navigation .menu-title.center {
  text-align: center;
}

.creator .navigation .menu-title > h3 {
  color: #2d5d91;
  font-weight: bold;
  line-height: 32px;
}

.creator .navigation .menu-title > button.close {
  display: block;
  background: none;
  border: 0;
  float: right;
  cursor: pointer;
  margin-right: 5px;
  opacity: 1;
}

.creator .navigation .back {
  vertical-align: top;
}

@keyframes left-arrow-stroke-draw {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #336699;
  }
}
