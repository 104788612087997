.creator div.product-title-info > div.description {
  max-height: 100px;
  overflow-y: hidden;
  position: relative;
}

.creator div.product-title-info .description.pageLoading {
  height: 120px;
}

.creator div.product-title-info h2.pageLoading {
  height: 50px;
}

.creator div.product-title-info > div.description.shade:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}

.creator div.product-title-info > button {
  margin-top: 10px;
}
