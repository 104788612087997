.creator #svg-color-component {
  position: absolute;
  z-index: 99;
  top: 120px;
  left: 11px;
}

.creator.mobileView #svg-color-component {
  bottom: auto;
  left: 14px;
  top: 60px;
}

.creator #svg-color-component > ul {
  list-style: none;
  padding: 0;
  height: 272px;
  display: flex;
  flex-flow: column wrap;
}

.creator.mobileView #svg-color-component > ul {
  height: 210px;
}

.creator #svg-color-component > ul > li {
  margin: 0 10px 10px 0;
  height: 35px;
  width: 35px;
}

.creator.mobileView #svg-color-component > ul > li {
  height: 24px;
  width: 24px;
}

.creator #svg-color-component > ul > li > button {
  display: inline-block;
  height: 100%;
  width: 100%;
  border: 1px solid #2d5d91;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
