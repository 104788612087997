.creator .spriteComponent {
  width: 132px;
  line-height: 100px;
  display: inline-block;
  border-radius: 8px;
  margin: 0 2px 2px 0;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #eee;
  position: relative;
  user-select: none;
  padding-bottom: 24px;
  animation: creator-fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  background-color: white;
}

.creator .spriteComponent.templateComponent {
  width: 198px;
  padding: 0;
}

.creator .spriteComponent.templateComponent > img {
  max-width: 175px;
}

.creator .spriteComponent > img {
  vertical-align: middle;
  display: inline-block;
  max-height: 80px;
  max-width: 110px;
  transform: scale(0.9);
  transition: transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.creator .my-motive a.spriteComponent {
  width: 170px;
  margin: 0 11px 11px 0;
}

.creator a.spriteComponent.error {
  border-color: #fb3f18;
}

@media (max-width: 1213px) {
  .creator a.spriteComponent {
    width: 120px;
  }
}

@media (max-width: 1140px) {
  .creator a.spriteComponent {
    width: 114px;
  }
}

@media (max-width: 1086px) {
  .creator a.spriteComponent {
    width: 105px;
  }
}

@media (max-width: 1005px) {
  .creator a.spriteComponent {
    width: 133px;
  }
}

@media (max-width: 846px) {
  .creator a.spriteComponent {
    width: 116px;
  }
}

.browserView.creator a.spriteComponent:hover {
  border: 1px dashed #336699;
  background: #ffffff;
}

.browserView.creator a.spriteComponent:active {
  border: 1px dashed #000000;
}

.creator a.spriteComponent:hover > img {
  transform: scale(1);
}

.mobileView.creator .spriteComponent.templateComponent {
  width: 170px;
  height: auto;
}

.mobileView.creator .spriteComponent.templateComponent > img {
  max-height: 150px;
  max-width: 150px;
}

.mobileView.creator .spriteComponent {
  width: 100px;
  height: 100px;
}

.mobileView.creator .spriteComponent > img {
  max-height: 80px;
  max-width: 80px;
}

.creator a.spriteComponent > .sprite-icon {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 3px;
  transform-style: preserve-3d;
  perspective: 100px;
  width: 30px;
  height: 30px;
}

.creator a.spriteComponent > .sprite-icon > img {
  width: 30px;
  height: 30px;
  vertical-align: top;
}

.creator a.spriteComponent > .bottom-right {
  bottom: 0;
  right: 0;
}

.creator a.spriteComponent > .top-left {
  top: 0;
  left: 0;
}

.creator a.spriteComponent > .top-right {
  top: 0;
  right: 0;
}

.creator a.spriteComponent > button:hover > img {
  animation: creator-slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.creator a.spriteComponent > button > img {
  width: 24px;
  height: 24px;
  transform: translateZ(-10px);
}

.creator a.spriteComponent > span.remove > img:hover {
  width: 26px;
  height: 26px;
}

.creator a.spriteComponent > span.remove {
  position: absolute;
  display: block;
}

.creator.component-modal > div {
  max-width: 500px;
}

.creator.mobileView.component-modal > div {
  max-width: 320px;
  padding: 10px;
}

.creator.mobileView.component-modal > div > img {
  max-width: 300px;
  max-height: 300px;
}

.creator.component-modal > div > img {
  max-width: 460px;
  max-height: 460px;
}
