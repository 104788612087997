.creator.mobileView div.bottom-container {
  position: fixed;
  text-align: left;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 3px 2px 19px 3px rgba(0, 0, 0, 0.36);
}

.creator.mobileView div.bottom-container > * {
  align-self: center;
}

.creator.mobileView div.bottom-container > a.info {
  border-radius: 50%;
  border: 2px solid #6c6c6c;
  display: inline-block;
  font-size: 0.7em;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  margin-right: 3px;
}

.creator.mobileView div.bottom-container > div.price {
  flex: 1 0 90px;
  font-weight: 700;
  font-size: 1.17em;
  margin-left: 10px;
  display: flex;
}
.creator div.bottom-container > div.price > div {
  display: block;
  clear: both;
  flex: 1;
  text-align: center;
}

.creator div.bottom-container > div.price .priceText {
  font-size: 1.1em;
}

.creator div.bottom-container > div.price button {
  border: none;
  background: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
  color: #336699;
  flex: 0 0 80px;
  text-align: center;
  font-size: 0.5em;
}

.creator.mobileView div.bottom-container > div.price .text {
  font-size: 0.5em;
  display: block;
}

.creator div.bottom-container > div.price button span {
  text-decoration: underline;
}
