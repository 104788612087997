.color-picker-component {
  position: absolute;
  background: #ffffff;
  text-align: left;
  width: 262px;
  border: 1px solid rgba(108, 108, 108, 0.2);
  z-index: 1001;
}

.color-picker-component.left {
  box-shadow: 0 -1px 2px rgba(119, 119, 119, 0.5);
}

.color-picker-component.right {
  box-shadow: -1px 0 2px rgba(119, 119, 119, 0.5);

}

.color-picker-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
}

.color-picker-component > div.content {
  padding: 10px 0 10px 10px;
}

.color-picker-component button.one-color-component {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 32px;
  border-radius: 50%;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(108, 108, 108, 0.4);
  cursor: pointer;
  padding: 0;
  box-shadow: none;
  user-select: none;
}

.color-picker-component button.one-color-component:hover {
  filter: invert(30%);
}

.color-picker-component > div.title {
  position: relative;
  padding: 10px 15px 5px 10px;
  border-bottom: 1px dashed rgba(108, 108, 108, 0.4);
  min-height: 50px;
  box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
}

.color-picker-component.left.top:before {
  left: -10px;
  top: 10px;
}

.color-picker-component.left.bottom:before {
  left: -10px;
  bottom: 10px;
}

.color-picker-component.right.top:before {
  right: -10px;
  top: 10px;
}

.color-picker-component.right.bottom:before {
  right: -10px;
  bottom: 10px;
}

.color-picker-component:before {
  content: " ";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
}

.color-picker-component.left:before {
  border-right: 10px solid white;
  filter: drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.3));
}

.color-picker-component.right:before {
  border-left: 10px solid white;
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.3));
}

.color-picker-component > div.title > button.close {
  display: block;
  margin-top: 4px;
  float: right;
  cursor: pointer;
  background: none;
  border: 0;
}

.color-picker-component > div.title > span {
  font-weight: 300;
  line-height: 32px;
  vertical-align: middle;
  display: inline-block;
}

.color-picker-component > div.title > button.close > img {
  width: 24px;
  height: 24px;
}

.color-picker-component > div.title > button.one-color-component {
  margin-bottom: 0;
}
